/**
Top Spacings
 */
.s-margin-top-small {
  margin-top: 2rem;
}

.s-margin-top-medium {
  margin-top: 2rem;
}

.s-margin-top-large {
  margin-top: 2rem;
}

.s-padding-small {
  padding: 0.5rem;
}

/**
Bottom spacings
 */
.s-margin-bottom-small {
  margin-bottom: 2rem;
}

.s-margin-bottom-medium {
  margin-bottom: 4rem;
}

.s-margin-bottom-large {
  margin-bottom: 6rem;
}

/**
Right spacing
 */
.s-margin-right-small {
  margin-right: 2rem;
}

.s-margin-right-medium {
  margin-bottom: 4rem;
}

.s-margin-right-large {
  margin-bottom: 6rem;
}

/**
Widths
 */
.s-width-full {
  width: 100%;
}
