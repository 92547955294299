@import 'mixins';
@import 'variables';

/**
Custom styles
*/
.hd--shadowed {
  @include border-radius();
  background-color: $c-shadow;
}

/**
Tables
 */
.hd--table-align-right {
  display: flex;
  justify-content: flex-end;
}

.hd--table-action-button {
  padding: 0;
  margin: 0;

  border-style: none;
  background: none;

  color: $c-company-accent;

  &:hover {
    cursor: pointer;
    color: $c-company-primary;
  }

  &:focus {
    outline: none;
  }
}

/**
Admin-theme
*/

.admin-theme {

  .hd--table-action-button {
    color: $c-admin-company-accent;

    &:hover {
      color: $c-admin-company-primary;
    }
  }

}



