/**
Global Material Overwrites
*/

.mat-sort-header-button {
  text-align: left;
}

.mat-card {
  border: $v-mat-card-border;
}

.mat-dialog-actions {
  button:not(:last-child) {
    margin-right: 0.2rem;
  }
}

.mat-list-base .mat-list-item .mat-list-text > p {
  font-weight: 200;
  font-size: 1rem;
}

.mat-tooltip {
  font-size: 0.9rem;
}

.mat-autocomplete {
  .mat-option-text {
    display: inherit;
  }
}

/**
Admin-theme
*/

.admin-theme {
  .mat-card {
    border: $v-admin-card-border;
  }
}
