@mixin border-radius-top($radius: 5px) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -ms-border-top-left-radius: $radius;
  border-top-left-radius: $radius;

  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -ms-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin user-select($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}


/**
 fxLayout Breakpoints definitions:
 https://github.com/angular/flex-layout/wiki/Responsive-API
*/

@mixin xs {
  @media screen and (max-width: 599px) {
    @content;
  }
}

@mixin lt-lg {
  @media screen and (max-width: 1279px) {
    @content;
  }
}
