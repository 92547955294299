/*
Color Palette generated from: http://mcg.mbitson.com
*/
/*
Color Palette generated from: http://mcg.mbitson.com
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
Material Palette references
*/
/*
Custom color definitions based on material palettes
*/
/*
Custom color definitions
*/
/*
Layers
*/
/*
Variables
*/
/**
Top Spacings
 */
.s-margin-top-small {
  margin-top: 2rem; }

.s-margin-top-medium {
  margin-top: 2rem; }

.s-margin-top-large {
  margin-top: 2rem; }

.s-padding-small {
  padding: 0.5rem; }

/**
Bottom spacings
 */
.s-margin-bottom-small {
  margin-bottom: 2rem; }

.s-margin-bottom-medium {
  margin-bottom: 4rem; }

.s-margin-bottom-large {
  margin-bottom: 6rem; }

/**
Right spacing
 */
.s-margin-right-small {
  margin-right: 2rem; }

.s-margin-right-medium {
  margin-bottom: 4rem; }

.s-margin-right-large {
  margin-bottom: 6rem; }

/**
Widths
 */
.s-width-full {
  width: 100%; }

/**
 fxLayout Breakpoints definitions:
 https://github.com/angular/flex-layout/wiki/Responsive-API
*/
/*
Color Palette generated from: http://mcg.mbitson.com
*/
/*
Color Palette generated from: http://mcg.mbitson.com
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
Material Palette references
*/
/*
Custom color definitions based on material palettes
*/
/*
Custom color definitions
*/
/*
Layers
*/
/*
Variables
*/
/**
Custom styles
*/
.hd--shadowed {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #f5f5f5; }

/**
Tables
 */
.hd--table-align-right {
  display: flex;
  justify-content: flex-end; }

.hd--table-action-button {
  padding: 0;
  margin: 0;
  border-style: none;
  background: none;
  color: #ededed; }
  .hd--table-action-button:hover {
    cursor: pointer;
    color: #5fabdc; }
  .hd--table-action-button:focus {
    outline: none; }

/**
Admin-theme
*/
.admin-theme .hd--table-action-button {
  color: #b1b1b1; }
  .admin-theme .hd--table-action-button:hover {
    color: #ff5922; }

/**
Global Material Overwrites
*/
.mat-sort-header-button {
  text-align: left; }

.mat-card {
  border: 1px solid #8bc7ff; }

.mat-dialog-actions button:not(:last-child) {
  margin-right: 0.2rem; }

.mat-list-base .mat-list-item .mat-list-text > p {
  font-weight: 200;
  font-size: 1rem; }

.mat-tooltip {
  font-size: 0.9rem; }

.mat-autocomplete .mat-option-text {
  display: inherit; }

/**
Admin-theme
*/
.admin-theme .mat-card {
  border: 1px solid #ffb2a9; }

body, html {
  height: 100%;
  background-color: #FFFFFF; }
